import axios from "axios";
import { baseUrl, getConfig } from "../config";

export const fetchMembers = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/getallusers`,
			getConfig(getState)
		);
		dispatch({ type: "GET_MEMBERS", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const findUserBySearch = (params) => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/searchmembers?${params}`,
			getConfig(getState)
		);
		const showModal = true;
		dispatch({ type: "GET_MEMBERS_SEARCH", payload: res.data });
		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data, showModal });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const getUserById = (id) => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/getmember/${id}`,
			getConfig(getState)
		);

		dispatch({ type: "GET_MEMBER_BY_ID", payload: res.data });
		// dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const newRegistrations = () => async (dispatch, getState) => {
	try {
		dispatch({ type: "SET_LOADING" });
		const res = await axios.get(
			`${baseUrl}/admin/API/newregistration`,
			getConfig(getState)
		);

		dispatch({ type: "NEW_REGISTRATION", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const fetchReferrals = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/api/getreferals`,
			getConfig(getState)
		);
		dispatch({ type: "GET_REFERRALS", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const confirmUser = (id) => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/confirm/${id}`,
			getConfig(getState)
		);

		dispatch({ type: "CONFIRM_USER_SUCCESSFUL", payload: res.data });
		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const deleteUser = (id) => async (dispatch, getState) => {
	try {
		const res = await axios.delete(
			`${baseUrl}/admin/API/deleteuser`,
			{
				data: {
					id,
				},
			},
			getConfig(getState)
		);
		dispatch({ type: "DELETE_USER_SUCCESSFUL", payload: res.data });
		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const acknowledgeReciept =
	(userdata, type) => async (dispatch, getState) => {
		try {
			let res;
			if (type === "initial") {
				res = await axios.post(
					`${baseUrl}/admin/API/acknowledgereciept`,
					{ userdata },
					getConfig(getState)
				);
			}
			if (type === "increase") {
				res = await axios.post(
					`${baseUrl}/admin/API/acknowledgeincreasereciept`,
					{ userdata },
					getConfig(getState)
				);
			}
			if (type === "decrease") {
				res = await axios.post(
					`${baseUrl}/admin/API/acknowledgedecreasereciept`,
					{ userdata },
					getConfig(getState)
				);
			}
			dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
		} catch (err) {
			dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
		}
	};

export const declineReciept = (userdata) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/API/declinereciept`,
			{ userdata },
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const sendEmail = (data) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/API/sendemail`,
			data,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR", payload: err.response?.data });
	}
};

export const wakeup = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/getmembers`,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR", payload: err.response?.data });
	}
};

export const addFinacialReport = (data) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/API/add-fin-report`,
			data,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const getFinReport = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/get-fin-report`,

			getConfig(getState)
		);

		dispatch({ type: "GET_FIN_REPORT", payload: res.data.finReports });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const deleteFinReport = (id) => async (dispatch, getState) => {
	try {
		const res = await axios.delete(
			`${baseUrl}/admin/API/delete-fin-report/${id}`,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const addLegalDoc = (data) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/API/add-legal-doc`,
			data,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const getLegalDocs = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/get-legal-doc`,

			getConfig(getState)
		);

		dispatch({ type: "GET_LEGAL_DOC", payload: res.data.legalDoc });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const deleteLegalDoc = (id) => async (dispatch, getState) => {
	try {
		const res = await axios.delete(
			`${baseUrl}/admin/API/delete-legal-doc/${id}`,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};
export const addProduct = (data) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/API/addproduct`,
			data,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};
export const updateProduct = (data) => async (dispatch, getState) => {
	try {
		const res = await axios.post(
			`${baseUrl}/admin/API/updateproduct`,
			data,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};

export const updateBankDetails = (id, data) => async (dispatch, getState) => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/API/update-bank-details/${id}`,
			data,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
		dispatch({
			type: "UPDATED_BANK_INFO",
			payload: res.data.bankDetails,
		});
	} catch (err) {
		dispatch({ type: "GET_ERROR_MSG", payload: err.response?.data });
	}
};
export const getProducts = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/getproducts`,
			getConfig(getState)
		);

		dispatch({ type: "GET_PRODUCTS", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const removeProducts = (id) => async (dispatch, getState) => {
	try {
		const res = await axios.delete(
			`${baseUrl}/admin/API/removeproducts`,
			{
				headers: {
					Content_Type: "application/json",
				},
				data: {
					id,
				},
			},

			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const fetchCommodityReq = () => async (dispatch, getState) => {
	try {
		const res = await axios.get(
			`${baseUrl}/admin/API/commodityrequests`,
			getConfig(getState)
		);

		dispatch({ type: "GET_COMMODITY_REQUEST", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const deleteCommodityReq = (id) => async (dispatch, getState) => {
	try {
		const res = await axios.delete(
			`${baseUrl}/admin/API/removerequest`,
			{
				headers: {
					Content_Type: "application/json",
				},
				data: {
					id,
				},
			},

			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const fetchLoanRequest = (page) => async (dispatch, getState) => {
	try {
		dispatch({ type: "SET_LOADING_TRUE" })
		const res = await axios.get(
			`${baseUrl}/admin/API/loanrequest?page=${page}`,
			getConfig(getState)
		);

		dispatch({ type: "SET_LOADING_FALSE" })

		dispatch({ type: "GET_LOAN_REQUEST", payload: res.data });
	} catch (err) {
		dispatch({ type: "SET_LOADING_FALSE" })
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const fetchApprovedLoanRequest = (page) => async (dispatch, getState) => {
	try {
		dispatch({ type: "SET_LOADING_TRUE" })
		const res = await axios.get(
			`${baseUrl}/admin/API/approvedloan?page=${page}`,
			getConfig(getState)
		);

		dispatch({ type: "SET_LOADING_FALSE" })

		dispatch({ type: "GET_LOAN_REQUEST", payload: res.data });
	} catch (err) {
		dispatch({ type: "SET_LOADING_FALSE" })
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const acknowledgeLoanRequest = (data) => async (dispatch, getState) => {
	try {
		const res = await axios.put(
			`${baseUrl}/admin/API/acceptloanreq`,
			data,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};

export const rejectLoanRequest = (id) => async (dispatch, getState) => {
	try {
		let data = {
			id: id,
		};
		const res = await axios.post(
			`${baseUrl}/admin/API/rejectloanreq`,
			data,
			getConfig(getState)
		);

		dispatch({ type: "GET_SUCCESS_MSG", payload: res.data });
	} catch (err) {
		dispatch({
			type: "GET_ERROR_MSG",
			payload: err?.response?.data,
		});
	}
};
